<template>
  <div>
    <div class="el-icon-warning ontext" style="margin-bottom: 10px">
      连锁线下退货配送商的退货交易数据不在此范围展示
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      :header-cell-style="{ background: '#F2F3F5' }"
      border
    >
      <el-table-column
        align="center"
        label="序号"
        type="index"
        :index="indexMethod"
        width="70"
      />
      <el-table-column
        prop="incomeExpenseType"
        label="收支类型"
        align="center"
      />
      <el-table-column prop="transactionType" label="交易类型" align="center" />
      <el-table-column
        prop="amount"
        label="采购单金额"
        align="center"
        width="120"
      />
      <el-table-column
        prop="transactionTime"
        label="交易时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="supplier"
        label="供应商"
        align="center"
        width="100"
      />
      <el-table-column
        prop="deliveryService"
        label="配送商"
        align="center"
        width="100"
      />
      <el-table-column
        prop="supplierDeliveryTime"
        label="配送商发货时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="settlementMethod"
        label="配送商结算方式"
        align="center"
      />
      <el-table-column prop="billStatus" label="是否结清" align="center" />
      <el-table-column
        prop="creditedDate"
        label="结清时间"
        align="center"
        width="170"
      />
      <el-table-column prop="billNumber" label="账单编号" align="center" />
      <el-table-column
        prop="originalOrderId"
        label="原订单号"
        align="center"
        width="170"
      />
      <el-table-column
        prop="originalGroupId"
        label="原主订单号"
        align="center"
        width="170"
      />
      <el-table-column
        prop="originalOrderGroupId"
        label="原订单批次号"
        align="center"
        width="170"
      />
      <el-table-column
        prop="paymentMethod"
        label="原单支付渠道"
        align="center"
        width="120"
      />
      <el-table-column
        prop="orderCreateTime"
        label="原订单下单时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="payTime"
        label="原订单支付时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="orderDeliveryTime"
        label="订单发货时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="settleFlag"
        label="原订单是否结算"
        align="center"
      />
      <el-table-column
        prop="settleTime"
        label="原订单结算时间"
        align="center"
        width="170"
      />
      <el-table-column prop="remark" label="备注" align="center" />
    </el-table>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    queryParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    //序号
    indexMethod(index) {
      return index + (this.queryParams.page - 1) * this.queryParams.perPage + 1;
    },
  },
};
</script>
<style lang='scss' scoped>
</style>