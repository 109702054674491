<template>
  <div class="merlends">
    <el-form
      ref="form"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleQuery"
    >
      <el-form-item label="原订单号：">
        <el-input
          v-model="queryParams.originalOrderId"
          placeholder="请输入原订单号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="原主订单号：">
        <el-input
          v-model="queryParams.originalGroupId"
          placeholder="请输入原主订单号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="原批次号：">
        <el-input
          v-model="queryParams.originalOrderGroupId"
          placeholder="请输入原批次号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="提现编号：">
        <el-input
          v-model="queryParams.txNo"
          placeholder="请输入提现编号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="下单时间：">
        <el-date-picker
          style="width: 380px"
          v-model="queryParams.orderCreateList"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="下单开始时间"
          end-placeholder="下单结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="支付时间：">
        <el-date-picker
          style="width: 380px"
          v-model="queryParams.paymentList"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="支付开始时间"
          end-placeholder="支付结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="配送商发货时间：">
        <el-date-picker
          style="width: 380px"
          v-model="queryParams.supplierDeliveryList"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="配送商发货开始时间"
          end-placeholder="配送商发货结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="发货时间：">
        <el-date-picker
          style="width: 380px"
          v-model="queryParams.deliveryList"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="发货开始时间"
          end-placeholder="发货结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结算时间：">
        <el-date-picker
          style="width: 380px"
          v-model="queryParams.settlementList"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="结算开始时间"
          end-placeholder="结算结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="提现时间：">
        <el-date-picker
          style="width: 380px"
          v-model="queryParams.withdrawList"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="提现开始时间"
          end-placeholder="提现结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="提现状态：">
        <el-select
          v-model="queryParams.withdrawStatus"
          placeholder="请选择提现状态"
          multiple
          clearable
          style="width: 270px"
        >
          <el-option label="未提现" :value="0"></el-option>
          <el-option label="提现中" :value="1"></el-option>
          <el-option label="已提现" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="交易类型：">
        <el-select
          v-model="queryParams.transactionType"
          placeholder="请选择交易类型"
          multiple
          clearable
          style="width: 300px"
        >
          <el-option label="销售下单" :value="0"></el-option>
          <el-option label="销售退货" :value="1"></el-option>
          <el-option label="销售冲货" :value="2"></el-option>
          <el-option label="销售退款" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否结算：">
        <el-select
          v-model="queryParams.settleFlag"
          placeholder="请选择结算状态"
          multiple
          clearable
        >
          <el-option label="未结算" :value="0"></el-option>
          <el-option label="已结算" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单类型：">
        <el-select
          v-model="queryParams.deliveryService"
          placeholder="请选择订单类型"
          multiple
          clearable
        >
          <el-option label="自发货" :value="1"></el-option>
          <el-option label="合纵" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单支付方式：">
        <el-select
          v-model="queryParams.paymentMethod"
          placeholder="请选择订单支付方式"
          multiple
          collapse-tags
          clearable
        >
          <el-option
            v-for="(item, index) in Paymentlist"
            :key="index"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="交易支付类型：">
        <el-select
          v-model="queryParams.paymentType"
          placeholder="请选择交易支付类型"
          multiple
          clearable
        >
          <el-option label="线下支付" :value="0"></el-option>
          <el-option label="线上支付" :value="1"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="备注：">
        <el-input
          v-model="queryParams.remark"
          placeholder="请输入备注"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="供应商：">
        <el-input
          v-model="queryParams.supplier"
          placeholder="请输入供应商"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="handleQuery"
          >查 询</el-button
        >
        <el-button size="small" type="primary" @click="handleReset"
          >重 置</el-button
        >
      </el-form-item>
    </el-form>
    <!-- tab切换 -->
    <div class="toponsd">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="销售订单" name="one" />
        <el-tab-pane label="采购订单" name="two" />
      </el-tabs>
      <div class="abobtn">
        <el-button size="small" type="primary" @click="downLoad"
          >导 出</el-button
        >
      </div>
    </div>
    <!-- 列表 -->
    <div>
      <!-- 销售订单 -->
      <SalesOrders
        v-show="activeName == 'one'"
        :loading="loading"
        :tableData="SalesList"
        :queryParams="queryParams"
      />
      <!-- 采购订单 -->
      <PurchaseOrder
        v-show="activeName == 'two'"
        :loading="loading"
        :tableData="PurchList"
        :queryParams="queryParams"
      />
    </div>
    <!-- 统计 -->
    <div
      class=""
      style="margin-top: 10px; background: rgb(242, 243, 245); padding: 10px"
    >
      <span>订单数量：{{ total }}</span>
      <span style="margin-left: 20px">
        <span v-show="activeName == 'one'"
          >销售下单合计：
          {{
            Number(moneyfrom.salesAmout) < 0
              ? "-" + "￥" + Math.abs(Number(moneyfrom.salesAmout))
              : "+" + "￥" + Math.abs(Number(moneyfrom.salesAmout))
          }}
        </span>
        <span v-show="activeName == 'two'"
          >采购进货合计：
          {{
            Number(moneyfrom.purchaseAmout) < 0
              ? "-" + "￥" + Math.abs(Number(moneyfrom.purchaseAmout))
              : "+" + "￥" + Math.abs(Number(moneyfrom.purchaseAmout))
          }}
        </span>
      </span>
      <span style="margin-left: 20px">
        <span v-show="activeName == 'one'">销售冲货金额：</span>
        <span v-show="activeName == 'two'">采购冲货合计：</span>
        <span>
          {{
            Number(moneyfrom.refundAmount) < 0
              ? "-" + "￥" + Math.abs(Number(moneyfrom.refundAmount))
              : "+" + "￥" + Math.abs(Number(moneyfrom.refundAmount))
          }}
        </span>
      </span>
      <span style="margin-left: 20px">
        <span v-show="activeName == 'one'">销售退货金额：</span>
        <span v-show="activeName == 'two'">采购退货合计：</span>
        <span>
          {{
            Number(moneyfrom.returnAmount) < 0
              ? "-" + "￥" + Math.abs(Number(moneyfrom.returnAmount))
              : "+" + "￥" + Math.abs(Number(moneyfrom.returnAmount))
          }}
        </span>
      </span>
      <span style="margin-left: 20px" v-show="activeName == 'one'">
        <span>销售退款金额：</span>
        <span>
          {{
            Number(moneyfrom.reversalAmount) < 0
              ? "-" + "￥" + Math.abs(Number(moneyfrom.reversalAmount))
              : "+" + "￥" + Math.abs(Number(moneyfrom.reversalAmount))
          }}
        </span>
      </span>
      <span style="margin-left: 20px" class="red">
        收支合计：
        <span
          >{{
            Number(moneyfrom.calcAll) < 0
              ? "-" + "￥" + Math.abs(Number(moneyfrom.calcAll))
              : "+" + "￥" + Math.abs(Number(moneyfrom.calcAll))
          }}
        </span>
      </span>
      <span style="margin-left: 20px" class="red">
        未结算订单：
        <span>{{ moneyfrom.notSettleNum }}笔</span>
      </span>
    </div>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { downLoadZip } from "@/utils/zipdownload";
import { createNamespacedHelpers } from "vuex";
const Order = createNamespacedHelpers("Order"); //vuex公共库
const billList = createNamespacedHelpers("billList"); //vuex公共库
import PurchaseOrder from "./PurchaseOrder.vue";
import SalesOrders from "./SalesOrders.vue";
export default {
  name: "accountStatement",
  components: {
    SalesOrders, //销售订单
    PurchaseOrder, //采购订单
  },
  watch: {
    //下单时间
    "queryParams.orderCreateList": {
      handler(newVal) {
        if (newVal) {
          this.queryParams.orderCreateStartTime = newVal[0];
          this.queryParams.orderCreateEndTime = newVal[1];
        } else {
          this.queryParams.orderCreateStartTime = "";
          this.queryParams.orderCreateEndTime = "";
        }
      },
      deep: true,
    },
    //支付时间
    "queryParams.paymentList": {
      handler(newVal) {
        if (newVal) {
          this.queryParams.paymentStartTime = newVal[0];
          this.queryParams.paymentEndTime = newVal[1];
        } else {
          this.queryParams.paymentStartTime = "";
          this.queryParams.paymentEndTime = "";
        }
      },
      deep: true,
    },
    //配送商发货时间
    "queryParams.supplierDeliveryList": {
      handler(newVal) {
        if (newVal) {
          this.queryParams.supplierDeliveryStartTime = newVal[0];
          this.queryParams.supplierDeliveryEndTime = newVal[1];
        } else {
          this.queryParams.supplierDeliveryStartTime = "";
          this.queryParams.supplierDeliveryEndTime = "";
        }
      },
      deep: true,
    },
    //发货时间
    "queryParams.deliveryList": {
      handler(newVal) {
        if (newVal) {
          this.queryParams.deliveryStartTime = newVal[0];
          this.queryParams.deliveryEndTime = newVal[1];
        } else {
          this.queryParams.deliveryStartTime = "";
          this.queryParams.deliveryEndTime = "";
        }
      },
      deep: true,
    },
    //结算时间
    "queryParams.settlementList": {
      handler(newVal) {
        if (newVal) {
          this.queryParams.settlementStartTime = newVal[0];
          this.queryParams.settlementEndTime = newVal[1];
        } else {
          this.queryParams.settlementStartTime = "";
          this.queryParams.settlementEndTime = "";
        }
      },
      deep: true,
    },
    //提现时间
    "queryParams.withdrawList": {
      handler(newVal) {
        if (newVal) {
          this.queryParams.withdrawStartTime = newVal[0];
          this.queryParams.withdrawEndTime = newVal[1];
        } else {
          this.queryParams.withdrawStartTime = "";
          this.queryParams.withdrawEndTime = "";
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      queryParams: {
        page: 1, // 页码
        perPage: 10, //每页大小
        originalOrderId: "", //原订单号
        originalGroupId: "", //原主订单号
        originalOrderGroupId: "", //原批次号
        txNo: "", //提现编号

        orderCreateStartTime: "", //下单开始时间
        orderCreateEndTime: "", //下单结束时间
        paymentStartTime: "", //支付开始时间
        paymentEndTime: "", //支付结束时间
        supplierDeliveryStartTime: "", //配送商发货开始时间
        supplierDeliveryEndTime: "", //配送商发货结束时间
        deliveryStartTime: "", //发货开始时间
        deliveryEndTime: "", //	发货结束时间
        settlementStartTime: "", //结算开始时间
        settlementEndTime: "", //结算结束时间
        withdrawStartTime: "", //提现开始时间
        withdrawEndTime: "", //提现结束时间

        orderCreateList: [], //下单时间
        paymentList: [], //支付时间
        supplierDeliveryList: [], //配送商发货时间
        deliveryList: [], //发货时间
        settlementList: [], //结算时间
        withdrawList: [], //提现时间

        withdrawStatus: [], //提现状态
        transactionType: [], //交易类型
        settleFlag: [], //是否结算
        deliveryService: [], //订单类型
        paymentMethod: [], //订单支付方式
        paymentType: [], //交易支付类型
        remark: "", //备注
        supplier: "", //供应商
      },
      // 支付方式
      Paymentlist: [],
      activeName: "one", //tab状态
      total: 0,
      loading: false,
      SalesList: [], //销售订单列表
      PurchList: [], //采购订单列表
      moneyfrom: {
        purchaseAmout: 0, //采购进货合计
        salesAmout: 0, //销售下单合计

        refundAmount: 0, //采购冲货合计、销售冲货金额
        returnAmount: 0, //采购退货合计、销售退货金额
        reversalAmoun: 0, //

        calcAll: 0, //收支合计
        notSettleNum: 0, //未结算订单
      },
      lastRequestId: 0, // 记录最后一次请求的ID
      lastResponse: null, // 记录最后一次请求的响应数据
    };
  },
  created() {
    this.getPaymentTypeder();
    let params = this.$route.params;
    if (params.data) {
      // this.queryParams=
    } else {
      //获取存储查询
      let path = this.$route.path;
      if (this.$store.getters.routData[path]) {
        Object.assign(
          this.queryParams,
          this.$store.getters.routData[path].queryParams
        );
        this.activeName = this.$store.getters.routData[path].activeName;
      }
    }
    this.getItem();
  },
  methods: {
    ...Order.mapActions([
      "getPaymentTypeList", //获取支付方式
    ]),
    ...billList.mapActions([
      "salesStatement", //销售对账单列表
      "purchaseStatement", //采购对账单列表
    ]),
    //序号
    indexMethod(index) {
      return index + (this.queryParams.page - 1) * this.queryParams.perPage + 1;
    },
    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
        activeName: this.activeName,
      };
      this.$store.dispatch("routing/querying", data);
    },
    //查询按钮操作
    handleQuery() {
      this.queryParams.page = 1;
      this.getItem();
    },
    /** 查询列表数据 */
    getList() {
      this.loading = true;
      if (this.activeName == "one") {
        this.salesStatement(this.queryParams).then((res) => {
          this.SalesList = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        });
      }
      if (this.activeName == "two") {
        this.purchaseStatement(this.queryParams).then((res) => {
          this.PurchList = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        });
      }
      this.setquery();
    },
    //** 获取页面所有数据 */
    async getItem() {
      await this.getList();
      const requestId = Date.now(); // 生成一个唯一请求ID
      this.lastRequestId = requestId; // 记录最后一次请求ID
      //获取订单统计
      var from = { ...this.queryParams };
      from.opt = "statistics";
      try {
        if (this.activeName == "one") {
          var response = await this.salesStatement(from); // 发起接口请求
        }
        if (this.activeName == "two") {
          var response = await this.purchaseStatement(from); // 发起接口请求
        }
        // 只保存最后一次请求的数据
        if (requestId === this.lastRequestId) {
          this.lastResponse = response;
          // 更新页面数据或进行其他操作
          this.updatePageData(this.lastResponse);
        }
      } catch (error) {
        // 处理请求错误
        // console.error("请求错误:", error);
      }
    },
    updatePageData(response) {
      if (response.data) {
        Object.assign(this.moneyfrom, response.data);
      }
    },
    // 重置
    handleReset() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getItem();
    },
    //切换tab状态
    async handleClick() {
      // Object.assign(this.queryParams, this.$options.data().queryParams);
      this.queryParams.page = 1;
      this.queryParams.perPage = 10;
      await this.getItem();
    },
    //** 获取支付方式 */
    async getPaymentTypeder() {
      let res = await this.getPaymentTypeList();
      if (res.code == 200) {
        this.Paymentlist = res.data;
      }
    },
    //** 下载 */
    downLoad() {
      if (this.activeName == "one") {
        downLoadZip(
          `/platformStatements/salesStatementExport`,
          "xlsx",
          "销售对账单",
          this.queryParams,
          "php",
          "post"
        );
      }
      if (this.activeName == "two") {
        downLoadZip(
          `/platformStatements/purchaseStatementExport`,
          "xlsx",
          "采购对账单",
          this.queryParams,
          "php",
          "post"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 5px;
}
.toponsd {
  margin-left: 10px;
  position: relative;
  .abobtn {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
</style>
