<template>
  <div>
    <el-table
      v-loading="loading"
      :data="tableData"
      :header-cell-style="{ background: '#F2F3F5' }"
      border
    >
      <el-table-column
        align="center"
        label="序号"
        type="index"
        :index="indexMethod"
        width="70"
      />
      <el-table-column prop="" label="收支类型" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.incomeExpenseType }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="交易类型" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.transactionType }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="交易金额" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.amount }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="transactionTime"
        label="交易时间"
        align="center"
        width="170"
      />
      <!-- <el-table-column prop="" label="交易支付类型" align="center" width="120">
        <template slot-scope="scope">
          <div>{{ scope.row.paymentType }}</div>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="originalOrderId"
        label="原订单号"
        align="center"
        width="200"
      />
      <el-table-column
        prop="originalGroupId"
        label="原主订单号"
        align="center"
        width="200"
      />
      <el-table-column
        prop="originalOrderGroupId"
        label="原订单批次号"
        align="center"
        width="200"
      />
      <el-table-column
        prop="payableAmount"
        label="原订单金额"
        align="center"
        width="110"
      />

      <el-table-column
        prop="paySerial"
        label="交易流水号"
        align="center"
        width="170"
      />
      <el-table-column
        prop="supplier"
        label="供应商"
        align="center"
        width="170"
      />
      <el-table-column
        prop="customer"
        label="客户名称"
        align="center"
        width="170"
      />
      <el-table-column prop="" label="订单类型" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.deliveryService }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="订单支付方式" align="center" width="150">
        <template slot-scope="scope">
          <div>{{ scope.row.paymentMethod }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="orderCreateTime"
        label="下单时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="payTime"
        label="支付时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="supplierDeliveryTime"
        label="配送商发货时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="orderDeliveryTime"
        label="订单发货时间"
        align="center"
        width="170"
      />
      <el-table-column prop="settleFlag" label="是否结算" align="center" />
      <el-table-column
        prop="settleTime"
        label="结算时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="withdrawalStatus"
        label="提现状态(仅在线支付)"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.withdrawalStatus }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="withdrawalTime"
        label="提现时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="txNo"
        label="提现编号"
        align="center"
        width="170"
      />
      <el-table-column prop="remark" label="备注" align="center" />
    </el-table>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    queryParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    //序号
    indexMethod(index) {
      return index + (this.queryParams.page - 1) * this.queryParams.perPage + 1;
    },
  },
};
</script>
<style lang="scss" scoped></style>
